import React, {useRef} from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Section from "./Section";

const Content = styled.div`
  background: white;
  padding: 3.125rem 0;
  
  .container {
    display: inline-block;
  }
`;

const Header = styled.h2`
  max-width: 16.563rem;
  margin: 0 0 1.25rem;
  padding: 0 1.25rem;

  @media only screen and (min-width: 1024px) {
    padding: 0;   
  }
`;

const SliderStyled = styled(Slider)`
  .slick {
    &-track {
      display: flex !important;
    }
    
    &-slide {
      cursor: grab;
      width: 70vw;
      display: flex;
      height: inherit !important;
      
      .slide-content {
        padding: 2rem 1.25rem;
        background: #f9f9f9;
        height: 100%;
        
        h3, p {
          opacity: .2;
        }
        
        h3 {
          margin-bottom: 1.25rem;
        }
      }
    }
    
    &-current {
      .slide-content {
        background: #faf9f9;
        
        h3, p {
          opacity: 1;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .slick {
      &-slide {
        width: 45vw;

        .slide-content {
          padding: 3rem 2.25rem;
        }
      }
    }
  }
`;

const Slide = styled.div`
  box-sizing: border-box;
  padding: 0 .625rem;
  height: 100%;
`;

const Reviews = ({heading, reviewsArray}) => {
    const slider = useRef(null);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        swipe: true,
        swipeToSlide: true,
        draggable: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: true,
        autoplay: true,
        variableWidth: true
    };

    return (
        <Section>
            <Content>
                <div className="container">
                    <div className="col-1 hidden-m"/>
                    <div className="col-10">
                        <Header>{heading}</Header>
                    </div>
                </div>
                <SliderStyled {...settings} ref={slider}>
                    {reviewsArray.map((review, index) => {
                        return (
                            <Slide key={index}>
                                <div className="slide-content">
                                    <h3>{review.heading}</h3>
                                    <p>{review.copy}</p>
                                </div>
                            </Slide>
                        );
                    })}
                </SliderStyled>
            </Content>
        </Section>
    );
};

export default Reviews;