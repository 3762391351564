import React from "react";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import Section from "./Section";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const QuickNav = styled.div`
    padding: 0 1.25rem;

  @media only screen and (min-width: 1024px) {
    padding: 0;
  }
`;

const Background = styled.div`
  background: var(--white);
  display: flex;
  flex-direction: column;
  margin-bottom: 3.125rem ;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  padding: 3.125rem 1.25rem 0;
  width: 100%;

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  li {
    line-height: 1.3;
    margin-bottom: 0.3125rem;
  }
  
  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    display: inline;
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

  }
  
  @media only screen and (min-width: 1024px) {
    padding: 3.125rem 1.25rem;
    width: 50%;
  }
`;

const SecondContent = styled(Content)`
  padding: 0 1.25rem 3.125rem;

  a {
    display: inline;
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

  }
  
  @media only screen and (min-width: 1024px) {
    padding: 3.125rem 1.25rem;
  }
`;

const QuickNavigation = (props) => {
      
  const serializers = {
    marks: {
      link: ({mark, children}) => {
        const { href } = mark
        return <AnchorLink to={href}>{children}</AnchorLink>
      },
      id: ({mark, children}) => {
        const { id } = mark
        return <span id={id}>{children}</span>
      },
    }
  }

    return (
      <Section>
        <QuickNav>
        <div className="container">
          <div className="col-1 hidden-m"/>
          <div className="col-10">
            <Background>
                <Content>
                  <BlockContent blocks={props.leftColumn._rawCopy} serializers={serializers}/>
                </Content>
                <SecondContent>
                  <BlockContent blocks={props.rightColumn._rawCopy} serializers={serializers}/>
                </SecondContent>
            </Background>
          </div>
        </div>
        </QuickNav>
      </Section>
    );
};

export default QuickNavigation;